import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import { Layout } from '../components/Layout';
import { SEO } from '../components/SEO';

const Error: FC<PageProps> = ({ location }) => (
  <Layout>
    <SEO pagetitle="ページが見つかりません" pagepath={location.pathname} />
    <h1>お探しのページは見つかりませんでした</h1>
  </Layout>
);

export default Error;
